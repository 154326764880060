import React from 'react';
import { styled } from 'stitches.config';

interface TagProps {
  text: string;
  iconUrl?: string;
}

export function Tag({ text, iconUrl }: TagProps) {
  return (
    <TagContainer>
      {iconUrl && <img src={iconUrl} alt="아이콘" />}
      <span
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          flexShrink: 1,
        }}
      >
        {text}
      </span>
    </TagContainer>
  );
}

const TagContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: 'fit-content',
  maxWidth: 'min(100%, 150px)',
  marginBottom: '4px',
  padding: '2px 6px',
  borderRadius: '4px',
  color: '#aa59d9',
  backgroundColor: '#f9f0ff',
  fontSize: '10px',
  fontWeight: 'bold',
  lineHeight: '16px',

  '& img:first-of-type': {
    marginRight: '2px',
  },
});
