import React from 'react';

import { styled } from 'stitches.config';
import { BADGE_COLOR, BADGE_TEXT } from '@src/constants/badge';
import { Badge as BadgeType } from '@src/models/project';

interface BadgeProps {
  type: BadgeType;
}

export function Badge({ type }: BadgeProps) {
  return (
    <BadgeContainer
      css={{
        backgroundColor: BADGE_COLOR[type],
      }}
    >
      {BADGE_TEXT[type]}
    </BadgeContainer>
  );
}

const BadgeContainer = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '2px 6px',
  borderRadius: '7px 0 8px',
  color: '#fff',
  backgroundColor: '#c869ff',
  fontSize: '10px',
  fontWeight: 700,
  lineHeight: '16px',
});
