import * as React from 'react';
import { PageProps } from 'gatsby';

import ProjectListOfGenrePage from '@src/components/pages/ProjectListOfGenre';
import { GeneralHead } from '@src/components/GeneralHead';
import { NavigationBar } from '@src/components/NavigationBar';
import { Footer } from '@src/components/Footer';
import { Genre } from '@src/models/menuInfo';
import { initLanguage } from '@src/utils/translation';
import { Layout } from '@src/components/Layout';
import { useQueryString } from '@src/hooks/useQueryString';

export interface ProjectListOfGenrePageData {
  genre: Genre;
}

interface IndexProps {
  pageContext: PageProps<unknown, ProjectListOfGenrePageData>['pageContext'];
}

/**
 * 정적 생성을 위한 컴포넌트
 * 레이아웃 컴포넌트와 실제 징르 페이지 컴포넌트를 렌더링
 * @param genre gatsby-node.ts에서 전달한 context, 장르들에 대한 데이터
 */
export default function Index({ pageContext: { genre } }: IndexProps) {
  const { isPreparing } = useQueryString();

  React.useEffect(() => {
    // 정적 생성 페이지라서 런타임으로 돌아가야하기 때문에 useEffect 안에 설정
    initLanguage();

    if (typeof window !== 'undefined') {
      if (genre.value === 'crimescene') {
        window.location.replace('https://crimescene.realworld.to/');
      }
    }
  }, [genre.value]);

  return (
    !isPreparing && (
      <Layout
        header={({ scrollElement }) => (
          <NavigationBar scrollRef={scrollElement} />
        )}
        footer={<Footer />}
      >
        {<ProjectListOfGenrePage genre={genre} />}
      </Layout>
    )
  );
}

export const Head = () => {
  return (
    <>
      <GeneralHead />
      <title>모두의 놀이터, 리얼월드 - 리얼월드</title>
    </>
  );
};
