import React from 'react';
import { Genre } from '@src/models/menuInfo';
import { ContainerWithMediaQuery } from '@src/styles/media';
import { styled } from 'stitches.config';
import { useTranslation } from 'react-i18next';
import { getProjectsDataByGenre } from '@src/apis/core/getProjectsDataByGenre';
import useSWRInfinite from 'swr/infinite';
import { useInView } from 'react-cool-inview';
import { LoadingSpinner } from '@src/components/LoadingSpinner';
import { HorizontalTileProjectItem } from '@src/components/ProjectItem/HorizontalTile';
import { GenreListSkeleton } from '@src/components/Skeleton/GenreListSkeleton';
import { useIsMobile } from '@src/hooks/useIsMobile';
import { VerticalProjectItem } from '@src/components/ProjectItem/Vertical';
import { GenreListMobileSkeleton } from '@src/components/Skeleton/GenreListMobileSkeleton';

interface ProjectListOfGenrePageProps {
  genre: Genre;
}

export default function ProjectListOfGenrePage({
  genre,
}: ProjectListOfGenrePageProps) {
  const { t } = useTranslation();
  const genreName = React.useMemo(() => t(genre.name), [genre.name, t]);
  const projectsTotalPages = React.useRef(0);
  const isMobile = useIsMobile({ breakpoint: 768 });

  const fetchGetProjectsDataByGenre = React.useCallback(
    async (keys: (string | number)[]) => {
      const data = await getProjectsDataByGenre({
        genreValue: genre.value,
        take: 10,
        page: (typeof keys[2] === 'string' ? parseInt(keys[2]) : keys[2]) + 1,
      });

      projectsTotalPages.current = data.data?.body.totalPages;

      return data;
    },
    [genre.value]
  );

  const getProjectDataByGenreKey = (
    pageIndex: number,
    previousPageData?: { totalPage: number }
  ) => {
    if (previousPageData && previousPageData.totalPage < pageIndex + 1)
      return null;
    return [`getProjectDataByGenre`, genre.value, pageIndex];
  };

  const {
    data: projectsData,
    error: getProjectsDataByGenreError,
    isLoading: isLoadingGetProjectsDataByGenre,
    size: projectsSize,
    setSize: setProjectsSize,
  } = useSWRInfinite(getProjectDataByGenreKey, fetchGetProjectsDataByGenre, {
    revalidateFirstPage: false,
  });

  React.useEffect(() => {
    // 에러시 콘솔
    if (getProjectsDataByGenreError) {
      console.error(getProjectsDataByGenreError);
    }
  }, [getProjectsDataByGenreError]);

  const { observe } = useInView({
    rootMargin: '150px 0px',
    onEnter: ({ unobserve }) => {
      unobserve();
      if (projectsTotalPages.current < projectsSize + 1) return;

      setProjectsSize(projectsSize + 1);
    },
  });

  return (
    <PageLayout>
      <TopSection>
        <Container>
          <Title>전체 {genreName} 게임</Title>
        </Container>
      </TopSection>
      <ListSection>
        <Container>
          {!projectsData ? (
            isMobile ? (
              <GenreListMobileSkeleton />
            ) : (
              <GenreListSkeleton />
            )
          ) : (
            <ProjectContainer>
              {projectsData
                .map(
                  ({
                    data: {
                      body: { projects },
                    },
                  }) => projects
                )
                .flat()
                .map((project) =>
                  isMobile ? (
                    <HorizontalTileProjectItem
                      key={project.id}
                      project={project}
                    />
                  ) : (
                    <VerticalProjectItem key={project.id} project={project} />
                  )
                )}
            </ProjectContainer>
          )}
          {!isLoadingGetProjectsDataByGenre && (
            <LoadingSpinnerContainer key={projectsSize} ref={observe}>
              {!projectsData ||
                projectsData[projectsData.length - 1].data.body.pageIndex ===
                  projectsData[projectsData.length - 1].data.body
                    .totalPages || <LoadingSpinner />}
            </LoadingSpinnerContainer>
          )}
        </Container>
      </ListSection>
    </PageLayout>
  );
}

const PageLayout = styled('div', {
  display: 'flex',
  flexGrow: '1',
  flexDirection: 'column',
});

const LoadingSpinnerContainer = styled('div', {
  width: '100%',
  height: '100px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const ProjectContainer = styled('div', {
  display: 'grid',
  gap: '32px 6px',
  width: '100%',
  gridTemplateColumns: 'repeat(2, calc(50% - 3px))',

  '@media (min-width: 768px)': {
    gridTemplateColumns: 'repeat(2, calc(50% - 9px))',
    gap: '32px 18px',
  },
});

const Title = styled('div', {
  fontSize: '21px',
  lineHeight: '28px',
  fontWeight: '700',
  marginBottom: '20px',
  whiteSpace: 'pre-wrap',
});

const TopSection = styled('section', {
  width: '100%',
  paddingTop: '100px',
  backgroundImage:
    'linear-gradient(97deg, rgba(210, 80, 255, 0.05) -4%, rgba(255, 211, 0, 0.05) 113%)',
});
const Container = styled(ContainerWithMediaQuery, {
  position: 'relative',
  margin: '0 auto',
  padding: '0 1.5rem',
  overflow: 'hidden',
});

const ListSection = styled('section', {
  width: '100%',
  padding: '1.5rem 0',
  overflow: 'hidden',
  flexGrow: '1',
});
