import React from 'react';
import ContentLoader from 'react-content-loader';

export const GenreListSkeleton = () => {
  return (
    <>
      <ContentLoader
        speed={0.8}
        width="100%"
        height="493"
        backgroundColor="#f4f4f4"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="8" ry="8" width="150" height="200" />
        <rect x="166" y="0" rx="8" ry="8" width="50" height="20" />
        <rect x="166" y="24" rx="8" ry="8" width="100" height="23" />

        <rect
          x="calc(50% + 9px)"
          y="0"
          rx="8"
          ry="8"
          width="150"
          height="200"
        />
        <rect
          x="calc(50% + 175px)"
          y="0"
          rx="8"
          ry="8"
          width="50"
          height="20"
        />
        <rect
          x="calc(50% + 175px)"
          y="24"
          rx="8"
          ry="8"
          width="100"
          height="23"
        />

        <rect
          x="calc(50% + 9px)"
          y="0"
          rx="8"
          ry="8"
          width="150"
          height="200"
        />
        <rect
          x="calc(50% + 175px)"
          y="0"
          rx="8"
          ry="8"
          width="50"
          height="20"
        />
        <rect
          x="calc(50% + 175px)"
          y="24"
          rx="8"
          ry="8"
          width="100"
          height="23"
        />

        <rect x="0" y="232" rx="8" ry="8" width="150" height="200" />
        <rect x="166" y="232" rx="8" ry="8" width="50" height="20" />
        <rect x="166" y="260" rx="8" ry="8" width="100" height="23" />

        <rect
          x="calc(50% + 9px)"
          y="232"
          rx="8"
          ry="8"
          width="150"
          height="200"
        />
        <rect
          x="calc(50% + 175px)"
          y="232"
          rx="8"
          ry="8"
          width="50"
          height="20"
        />
        <rect
          x="calc(50% + 175px)"
          y="24"
          rx="8"
          ry="8"
          width="100"
          height="23"
        />

        <rect
          x="calc(50% + 9px)"
          y="232"
          rx="8"
          ry="8"
          width="150"
          height="200"
        />
        <rect
          x="calc(50% + 175px)"
          y="232"
          rx="8"
          ry="8"
          width="50"
          height="20"
        />
        <rect
          x="calc(50% + 175px)"
          y="260"
          rx="8"
          ry="8"
          width="100"
          height="23"
        />
      </ContentLoader>
    </>
  );
};
