import * as React from 'react';

import { styled } from 'stitches.config';
import TwitterLogo from '@assets/icons/share/twitter_icon.svg';
import NaverLogo from '@assets/icons/naver-icon.svg';
import InstagramLogo from '@assets/icons/instagram-icon.svg';
import YoutubeLogo from '@assets/icons/youtube-icon.svg';
import { Button } from '../Button';
import { ContainerWithMediaQuery } from '@src/styles/media';
import { Trans, useTranslation } from 'react-i18next';

export function Footer() {
  const { t } = useTranslation();

  return (
    <StyledFooter>
      <Container>
        <FirstArea>
          <InfoTitle marginBottom={1}>
            <b>{t('uniquegood_company')}</b>
          </InfoTitle>
          <p style={{ fontSize: '13px', lineHeight: 1.8 }}>
            <Trans
              i18nKey="company_info"
              values={{
                businessNumber: '524-88-00746',
                registrationNumber: '2018-서울성동-0760호',
                address: t('company_address'),
                tel: '070-8706-1010',
              }}
            >
              <b>사업자등록번호</b> 사업자등록번호 | <b>통신판매업신고번호</b>{' '}
              통신판매업신고번호 <b>대표이사</b> 대표이사 | <b>전화</b> 전화번호
            </Trans>
          </p>
        </FirstArea>
        <SecondArea>
          <InfoTitle marginBottom={2}>Contact</InfoTitle>
          <SnsList>
            <a
              href="https://twitter.com/Realworld_to"
              rel="noreferrer"
              target="_blank"
            >
              <Image src={TwitterLogo} width={33.78} height={33.78} />
            </a>
            <a
              href="https://www.instagram.com/realworld.to"
              rel="noreferrer"
              target="_blank"
            >
              <Image src={InstagramLogo} />
            </a>
            <a
              href="https://blog.naver.com/uniquegoodcompany"
              rel="noreferrer "
              target="_blank"
            >
              <Image src={NaverLogo} />
            </a>
            <a
              href="https://www.youtube.com/channel/UCf0yUWNWZF_0sfpeX3PhEcQ"
              rel="noreferrer "
              target="_blank"
            >
              <Image src={YoutubeLogo} />
            </a>
          </SnsList>
          <Button
            type="solid"
            color="primary"
            height={40}
            css={{ width: '200px' }}
            onClick={() => window.open('https://rwd.to/contact')}
          >
            {t('customer_support')}
          </Button>
        </SecondArea>
        <div>
          <InfoTitle marginBottom={2}>Business</InfoTitle>
          <Button
            type="outline"
            color="dark"
            height={40}
            css={{ width: '200px' }}
            onClick={() => window.open('https://rwd.to/rqstform_site')}
          >
            {t('contact')}
          </Button>
        </div>
      </Container>
    </StyledFooter>
  );
}

const StyledFooter = styled('footer', {
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '40px',
  paddingBottom: '100px',
  backgroundColor: '$backgroundDarkBlue',
});

const Container = styled(ContainerWithMediaQuery, {
  display: 'flex',
  justifyContent: 'flex-start',

  width: '100%',
  padding: '0 1.5rem',

  color: '#fff',

  '@bpMax991': {
    flexDirection: 'column',
  },
});

const FirstArea = styled('div', {
  flexShrink: 1,
  marginRight: '2.5rem',

  '@bpMax991': {
    marginBottom: '26px',
  },
});

const SecondArea = styled('div', {
  marginRight: '2.5rem',
  width: 'fit-content',

  '@bpMax991': {
    marginBottom: '10px',
  },
});

const InfoTitle = styled('div', {
  variants: {
    marginBottom: {
      1: {
        marginBottom: '0.25rem',
      },
      2: {
        marginBottom: '0.5rem',
      },
    },
  },
});

const SnsList = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '1rem',
});

const Image = styled('img', {
  verticalAlign: 'middle',
});
