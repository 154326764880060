import { Badge } from '@src/models/project';

export const BADGE_COLOR: Record<Badge, string> = {
  [Badge.NEW]: '#c869ff',
  [Badge.HOT]: '#ff4545',
  [Badge.IS_PREPARING]: 'rgba(0, 0, 0, 0.6)',
};

export const BADGE_TEXT: Record<Badge, string> = {
  [Badge.NEW]: 'NEW',
  [Badge.HOT]: 'HOT',
  [Badge.IS_PREPARING]: '준비중',
};
