import React from 'react';
import ContentLoader from 'react-content-loader';

export const GenreListMobileSkeleton = () => {
  return (
    <>
      <ContentLoader
        speed={0.8}
        width="100%"
        height="493"
        backgroundColor="#f4f4f4"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="8" ry="8" width="calc(50% - 3px)" height="175" />
        <rect
          x="calc(50% + 3px)"
          y="0"
          rx="8"
          ry="8"
          width="calc(50% - 3px)"
          height="175"
        />

        <rect
          x="0"
          y="243"
          rx="8"
          ry="8"
          width="calc(50% - 3px)"
          height="175"
        />
        <rect
          x="calc(50% + 3px)"
          y="243"
          rx="8"
          ry="8"
          width="calc(50% - 3px)"
          height="175"
        />
      </ContentLoader>
    </>
  );
};
