import { styled } from 'stitches.config';

export const ContainerWithMediaQuery = styled('div', {
  '@bpMin1200': {
    maxWidth: '1140px',
  },
  '@bpMax1199': {
    maxWidth: '960px',
  },
  '@bpMax991': {
    maxWidth: '720px',
  },
  '@bpMax767': {
    maxWidth: '540px',
  },
});
