import { Project } from '@src/models/project';
import { coreApi } from '..';

interface getProjectsDataByGenreResponse {
  data: {
    body: {
      count: number;
      pageIndex: number;
      projects: Project[];
      totalPages: number;
    };
  };
  message: string;
  success: boolean;
}

interface getProjectsDataByGenreProps {
  genreValue: string;
  take: number;
  page: number;
}

export async function getProjectsDataByGenre({
  genreValue,
  take,
  page,
}: getProjectsDataByGenreProps) {
  const { data } = await coreApi.get<getProjectsDataByGenreResponse>(
    `/api/mainpage/genres/${genreValue}`,
    { params: { take, page } }
  );

  return data;
}
