import React from 'react';
import { Link } from 'gatsby';

import { styled } from 'stitches.config';
import { Badge } from '@src/components/Badge';
import { Tag } from '@src/components/Tag';
import {
  filterGameType,
  filterPrice,
  getIsHaveLocation,
  getProjectSingleBadgeType,
  getProjectDetailUrl,
} from '@src/constants/project';
import { Project } from '@src/models/project';
import LocationIcon from '@assets/icons/location.svg';
import { ClickEventName, track } from '@src/track';

interface VerticalProjectItemProps {
  project: Project;
}

export function VerticalProjectItem({ project }: VerticalProjectItemProps) {
  const filteredPrice = React.useMemo(() => filterPrice(project), [project]);
  const badgeType = React.useMemo(
    () => getProjectSingleBadgeType(project),
    [project]
  );

  const handleItemClick = () => {
    track.onClick({
      clickEventName: ClickEventName.click_project,
      params: {
        projectName: project.name,
        projectId: project.id,
      },
    });
  };

  return (
    <div key={project.id} onClick={handleItemClick}>
      <Link to={getProjectDetailUrl(project)}>
        <div style={{ display: 'flex' }}>
          <VerticalListImage
            css={{
              backgroundImage: `url('${
                project.verticalImageUrl || project.imageUrl
              }')`,
            }}
          >
            {badgeType && <Badge type={badgeType} />}
          </VerticalListImage>
          <div style={{ flexShrink: 1 }}>
            <div style={{ marginBottom: '16px' }}>
              <Tag
                text={filterGameType(project)}
                iconUrl={getIsHaveLocation(project) ? LocationIcon : undefined}
              />
              <ProjectName>{project.name}</ProjectName>
              <div>
                <ProjectPrice>
                  {filteredPrice.discount && (
                    <Discount>{filteredPrice.discount}</Discount>
                  )}
                  <span>{filteredPrice.price}</span>
                  <ProjectOriginalPrice>
                    {filteredPrice.originalPrice}
                  </ProjectOriginalPrice>
                </ProjectPrice>
              </div>
            </div>
            <ProjectDescription>{project.description}</ProjectDescription>
            {project.tags && (
              <ProjectTagContainer>
                {project.tags.map((tag) => (
                  <ProjectTag key={tag}>{tag}</ProjectTag>
                ))}
              </ProjectTagContainer>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
}

const VerticalListImage = styled('div', {
  position: 'relative',
  width: '120px',
  height: '160px',
  marginRight: '16px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '8px',

  '@bpMin568': {
    width: '150px',
    height: '200px',
  },
});

const ProjectName = styled('h2', {
  display: '-webkit-box',
  maxHeight: '46px',
  fontSize: '18px',
  fontWeight: '700',
  lineHeight: '23px',
  wordWrap: 'break-word',
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',

  overflow: 'hidden',
  textOverflow: 'ellipsis',

  '@bpMax767': {
    fontSize: '16px',
  },
});

const ProjectPrice = styled('div', {
  fontSize: '14px',
  fontWeight: '500',
  color: '#626262',
  lineHeight: '20px',

  '& :first-of-type': {
    marginRight: '4px',
  },
});

const ProjectOriginalPrice = styled('span', {
  color: '#c3c3c3',
  textDecorationLine: 'line-through',
});

const ProjectDescription = styled('div', {
  color: '#626262',

  '@bpMax767': {
    fontSize: '12px',
  },
});

const ProjectTagContainer = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
});

const ProjectTag = styled('span', {
  marginRight: '8px',
  padding: '2px 6px',
  color: '#626262',
  backgroundColor: '#f4f4f4',
  borderRadius: '4px',
  fontSize: '13px',
  fontWeight: '700',

  '@bpMax767': {
    fontSize: '10px',
  },
});

const Discount = styled('span', {
  color: '#c869ff',
});
